.play-page {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #232227;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #232227;
  z-index: 10;
}

.ad-container {
  margin-top: 90px;
  width: 260px;
  height: 520px;
}

.ad-banner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.game-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.game-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

/* Add back button styles */
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: #1A95FF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #1577CC;
} 