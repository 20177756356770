.play-menu-bar {
  width: 90%;
  max-width: 363px;
  min-width: 280px;
  height: auto;
  min-height: 62px;
  background-color: #D4D1E1;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 2;
  overflow: hidden;
}

.loading-text {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.progress-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.progress-bar {
  height: 100%;
  background-color: #FFD700;
  transition: width 0.05s linear;
}

.play-button {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  cursor: not-allowed;
  background-color: #4A4A4A;
  color: white;
  transition: all 0.3s ease;
}

.play-button.enabled {
  background-color: #1A95FF;
  cursor: pointer;
}

.play-button.enabled:hover {
  background-color: #1577CC;
} 