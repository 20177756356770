.landing-page {
  background-color: #232227;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  text-align: center;
}

.landing-content {
  max-width: 600px;
  padding: 40px;
  background-color: #2C2B30;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.landing-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.landing-content h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

.landing-content p {
  font-size: 16px;
  margin-bottom: 24px;
  color: #B0B0B0;
} 