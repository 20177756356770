/* HomePage.css */

html, body {
  overscroll-behavior: auto; /* Restore bounce effect for scrolling */
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  touch-action: pan-y; /* Allow vertical touch scrolling */
}

.games-title {
  font-family: 'SF Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0;
  margin-top: 20px; /* Distance between MenuBar and "Browse Games" */
  margin-bottom: 20px;
  text-align: left;
  padding-left: 22px;
}

/* Game Card Grid */
.game-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  padding: 0 28px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 50px; /* Add more space at the bottom */
}
