/* src/components/MenuBar.css */
.menu-bar {
  width: 90%;
  max-width: 363px;
  min-width: 280px;
  height: auto;
  min-height: 62px;
  background-color: #2C2B30;
  border-radius: 12px;
  position: absolute; /* Default positioning */
  left: 50%;
  transform: translateX(-50%);
  top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 2;
}

/* Specific style for HomePage MenuBar */
.home-page .menu-bar {
  position: relative; /* Change to relative for HomePage */
  margin: 14px auto; /* Use margin instead of absolute positioning */
  transform: none; /* Remove transform */
  left: auto; /* Remove left positioning */
  top: auto; /* Remove top positioning */
}

/* Logo */
.menu-logo {
  width: clamp(32px, 5vw, 36px); /* Responsive logo size */
  height: clamp(32px, 5vw, 36px);
  cursor: pointer;
  flex-shrink: 0; /* Prevent shrinking on smaller screens */
}

/* Save My Progress */
.save-progress {
  font-size: clamp(14px, 4vw, 18px); /* Increased font size range */
  color: #1A95FF;
  cursor: pointer;
  font-weight: normal;
  margin-left: 16px; /* Specific left margin to position after logo */
  margin-right: auto; /* Push menu icon to the right */
  flex-grow: 0; /* Don't grow to fill space */
  white-space: nowrap;
  text-align: left; /* Align text to the left */
}

/* Menu Icon */
.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  padding: 6px; /* Reduced from 8px */
  min-width: 20px; /* Reduced from 24px */
}

.menu-bar-line {
  width: 18px; /* Reduced from 20px */
  height: 2px;
  background-color: white;
  border-radius: 20px;
}

/* Popup Menu */
.menu-popup {
  position: fixed; /* Changed to fixed for mobile */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 300px;
  background-color: #2C2B30;
  border-radius: 12px;
  padding: 20px; /* Increased padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Ensure the popup stays above other elements */
}

.menu-popup .menu-close {
  background: none;
  border: none;
  font-size: 24px; /* Larger for touch */
  color: white;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px; /* Added padding for touch */
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 40px; /* Increased margin */
}

.menu-list li {
  margin-bottom: 16px; /* Increased spacing */
}

.menu-list li a {
  text-decoration: none;
  color: #1A95FF; /* Match the new text color */
  font-size: 16px; /* Larger for mobile */
  padding: 8px 0; /* Added padding for touch */
  display: block; /* Make entire area clickable */
}

.menu-list li a:hover {
  text-decoration: underline;
}

/* Add media queries for different screen sizes */
@media screen and (max-width: 360px) {
  .menu-bar {
    width: 98%;
    padding: 6px 10px;
  }

  .save-progress {
    margin-left: 12px; /* Slightly smaller margin on mobile */
    font-size: clamp(13px, 3.5vw, 16px); /* Slightly smaller on very small screens */
  }
}

/* Add new media query for larger screens */
@media screen and (min-width: 768px) {
  .menu-bar {
    padding: 12px 24px;
  }

  .save-progress {
    margin-left: 24px; /* Larger margin on bigger screens */
  }
}
