/* GamePage.css */

.game-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  padding: 0;
  overflow-y: auto;
  position: relative;
  padding-top: 250px; /* Increased to account for title pill overlap */
}

/* Header Image */
.header-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 223px;
  z-index: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden; /* Add this to ensure the image respects the border radius */
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Title Pill */
.game-title-pill {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 196px;
  width: 242px;
  height: 53px;
  padding: 10px 18px;
  background-color: #2C2B30;
  border-radius: 18px;
  border: 1px solid #4F4E53;
  box-sizing: border-box;
  white-space: nowrap;
  z-index: 2;
  font-weight: bold;
  font-size: 24px;
  color: white;
  text-align: center;
}

/* Info Boxes */
.info-boxes {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 25px; /* Space after buttons */
  position: relative;
  z-index: 1;
}

.info-box {
  width: 97px;
  height: 110px;
  background-color: #2C2B30;
  border: 1px solid #4F4E53;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: space-between; /* Distribute items vertically */
  border-radius: 12px;
  box-sizing: border-box;
  padding: 0; /* Remove padding to prevent content shrinking */
}

.info-icon {
  width: 30px;
  height: 30px;
  fill: white; /* Keep the SVG's color */
  margin-top: 18px; /* Position the SVG 18px from the top */
  margin-bottom: 6px;
}

.info-text {
  font-size: 14px;
  color: white;
  font-weight: 600;
  margin: 0; /* No extra margins */
  text-align: center;
}

.info-subtext {
  font-size: 12px;
  color: #B0B0B0;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 18px; /* Position the subtitle 18px from the bottom */
  text-align: center;
}

/* About Section */
.about-section {
  margin: 25px 16px; /* Space after info boxes */
  padding: 0 10px;
}

.about-text {
  font-size: 15px;
  color: white;
  letter-spacing: -0.03em;
  font-weight: normal;
  text-align: center;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

/* Buttons */
.button-container {
  position: static;
  margin-top: 40px; /* Adjust spacing from title */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.game-play-button,
.game-multiplayer-button {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  width: calc(100% - 40px);
}

.game-play-button {
  background-color: #1A95FF;
  color: white;
  border: none;
}

.game-multiplayer-button {
  background-color: transparent;
  color: #1A95FF;
  border: 2px solid #1A95FF;
}

/* Add responsive adjustments */
@media screen and (max-width: 768px) {
  .header-image-container {
    height: 223px; /* Keep consistent height instead of vh */
  }
}
